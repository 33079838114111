<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="processModal"
    :title="$t('Process Outgoing Shipments')"
    :buttons-hidden="true" autocomplete="nofill">
    <shipblu-table
      multiple
      v-model="selected"
      :data="processShipments"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('Customer Box No.')}}</shipblu-th>
        <shipblu-th>{{$t('Outgoing Shipment ID')}}</shipblu-th>
        <shipblu-th>{{$t('No. of Products')}}</shipblu-th>
        <shipblu-th>{{$t('Weight')}}</shipblu-th>
        <shipblu-th>{{$t('Storage Slot')}}</shipblu-th>
        <shipblu-th>{{$t('Box Size')}}</shipblu-th>
        <shipblu-th v-if="$route.name === 'operations-received-products'">{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].name">
            {{ data[indextr].po_box ? data[indextr].po_box.box_number : 'N/A' }}
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].name">
            {{ data[indextr].id }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].boxNumber">
            {{ data[indextr].product_set.length }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].date">
            <p v-if="!data[indextr].edit">{{ data[indextr].total_weight }}</p>
            <div v-else class="relative text-left bg-transparent">
              <label :class="updatedProduct.total_weight ? 'visible' : 'hidden'" class="input-label" for="name">{{ $t('Weight') + ' *' }}</label>
              <input id="name" v-validate="'required|decimal'" :placeholder="$t('Weight') + ' *'" name="weight" v-model="updatedProduct.total_weight" :class="updatedProduct.total_weight ? 'input-customer-info-filled' : ''" class="w-28 bg-transparent input-customer-info"/>
              <span class="text-danger text-xs" v-show="errors.has('weight')">{{ errors.first('weight') }}</span> 
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].shipment">
            {{ data[indextr].storage_slot }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].shipment">
            <p v-if="!data[indextr].edit">{{ data[indextr].box_size ? data[indextr].box_size.name : 'N/A' }}</p>
            <div v-else class="relative text-left bg-transparent">
              <v-select :class="updatedProduct.box_size ? 'input-customer-info-filled' : ''" class="w-36 input-customer-info bg-transparent"
                autocomplete="nofill" label="name" name="box size" v-model="updatedProduct.box_size" v-validate="'required'"
                :placeholder="$t('Box Size') + '*'" :options="boxSizes"/>
              <span class="text-danger text-xs" v-show="errors.has('box size')">{{ errors.first('box size') }}</span>
            </div>
          </shipblu-td>

          <shipblu-td v-if="$route.name === 'operations-received-products'" :data="data[indextr].actions">
            <button v-if="!data[indextr].edit" class="actions-dropdown relative">
              <div class="m-auto flex items-center justify-center border-blue actions-btn">
                <span class="material-icons text-lg text-blue">more_horiz</span>
              </div>
              <div class="dropdown-list">
                <button @click="allowEdit(data[indextr])">
                  <span class="rtl-rotate-icon mr-1 material-icons-outlined">edit</span>
                  <span>{{ $t('Process Order') }}</span>
                </button>
              </div>
            </button>
            <div class="flex" v-else>
              <span @click="updateProduct(data[indextr])" class="material-icons-round text-success text-2xl">check_circle</span>
              <span @click="data[indextr].edit = false" class="material-icons-round text-danger text-2xl">cancel</span>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <div class="grid grid-cols-2 gap-4 mt-10">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button @click="createList" class="active-btn btn">{{ $t('Confirm') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../layouts/shipblu-components/ShipBluPrompt.vue'
import ShipbluTable from '../../../layouts/shipblu-components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/shipblu-components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/shipblu-components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/shipblu-components/ShipBluTd.vue'
import { sendRequest } from '../../../axios/requestHelper'

export default {
  props: ['processModal'],
  data () {
    return {
      selected: [],
      processShipments: [],
      updatedProduct: {
        box_size: {}
      },
      boxSizes: [],
      file_URl: ''
    }
  },
  components: {
    ShipbluPrompt,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd
  },
  watch: {
    'processModal' () {
      this.loadShipments()
      this.loadBoxSizes()
    }
  },
  methods: {
    rowEdit (data) {
      this.processShipments.forEach(item => {
        if (data.id === item.id) {
          item.edit = true
        }
      })
    },
    loadShipments () {
      sendRequest(this, `api/shipments/?status=${this.$route.name === 'operations-received-products' ? 'preparing_shipment' : 'ready_for_shipping'}&products_gte=1`, 'get', null, true,
        (response) => {
          response.data.map(item => { item.edit = false })
          this.processShipments = response.data
        }
      )
    },
    loadBoxSizes () {
      sendRequest(this, 'api/shipping-box-sizes/', 'get', null, true,
        (response) => {
          this.boxSizes = response.data
        }
      )
    },
    allowEdit (data) {
      data.edit = true
      this.updatedProduct = {...data}
    },
    closeModal () {
      this.$validator.pause()
      this.$emit('processModal', false)
    },
    updateProduct () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const product = [
            {
              id: this.updatedProduct.id,
              total_weight: Number(this.updatedProduct.total_weight),
              box_size: Number(this.updatedProduct.box_size.id)
            }
          ]
          sendRequest(this, 'api/shipments/', 'put', product, true,
            () => {
              this.loadShipments()
            }
          )
        }
      })
    },
    createList () {
      const shipments = []
      if (this.$route.name === 'operations-received-products') {
        this.processShipments.forEach(item => { 
          if (item.total_weight) {
            shipments.push(item.id)
          }
        })
      } else {
        this.processShipments.forEach(item => {
          shipments.push(item.id)
        })
      }
      sendRequest(this, `api/shipments/${this.$route.name === 'operations-received-products' ? 'shipping-lists' : 'packing-lists'}/`, 'post', {shipments}, true,
        (response) => {
          this.file_URl = response.data.pdf_link
          window.open(this.file_URl, '_blank')
        }
      )
    }
  }
}
</script>