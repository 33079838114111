<template>
  <div>
    <div class="flex items-center justify-between">
      <p class="font-medium text-darkblue sm:text-2xl text-xl">{{$t('Outgoing Shipments')}}</p>
      <button @click="openProductModal()" class="add-button bg-blue">
        <span class="text-2xl">+</span>
      </button>
    </div>
    <div class="mt-6 lg:float-left">
      <button @click="processProductModal()" class="font-medium btn text-white bg-success text-sm">
        {{$t('Process Outgoing Shipments')}}
      </button>
    </div>
    <shipblu-table
      multiple
      v-model="selected"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="shipments"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('Customer Box No.')}}</shipblu-th>
        <shipblu-th>{{$t('Outgoing Shipment ID')}}</shipblu-th>
        <shipblu-th>{{$t('No. of Products')}}</shipblu-th>
        <shipblu-th>{{$t('Total Weight')}}</shipblu-th>
        <shipblu-th>{{$t('Total Volumetric Weight')}}</shipblu-th>
        <shipblu-th>{{$t('Box Size')}}</shipblu-th>
        <shipblu-th>{{$t('Dispatch Date')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].boxNumber">
            {{ data[indextr].po_box.box_number }}
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].ID">
            {{ data[indextr].id }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].products">
            {{ data[indextr].product_set.length }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].weight">
            {{ data[indextr].total_weight }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].volumetricWeight">
            {{ data[indextr].total_volumetric_weight }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].boxSize">
            {{ data[indextr].box_size ? data[indextr].box_size.name : 'N/A' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].date">
            {{ data[indextr].arrival_date }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status">
            <div class="flex items-start justify-center items-center px-2" :class="data[indextr].status">
              <p class="status-border"></p>
              <p class="capitalize ml-1 w-full">{{ data[indextr].status ? data[indextr].status.split('_').join(' ') : ''}}</p>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <process-products :processModal="processModal" @processModal="processModal = $event"></process-products>
    <add-shipment :shipmentModal="shipmentModal" :shipment="shipment" 
      @shipmentModal="shipmentModal = $event" @loadData="loadData"></add-shipment>
  </div>
</template>

<script>
import ShipbluTable from '../../layouts/shipblu-components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/shipblu-components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/shipblu-components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/shipblu-components/ShipBluTd.vue'
import { sendRequest } from '../../axios/requestHelper'
import common from '../../assets/utils/common'
import AddShipment from './components/AddShipment.vue'
import ProcessProducts from './components/ProcessProducts.vue'

export default {
  data () {
    return {
      processModal: false,
      shipmentModal: false,
      maximumItems: 10,
      selected: [],
      shipments: [
        {
          product_set: [],
          box_size: {},
          po_box: {}
        }
      ],
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      shipment: {}
    }
  },
  methods: {
    processProductModal () {
      this.processModal = true
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadBlackListSearch)
    },
    openProductModal () {
      this.shipment = {}
      this.shipmentModal = true
    },
    closeModal () {
      this.shipmentModal = false
      this.slot = {}
    },
    loadData () {
      sendRequest(this, 'api/shipments/', 'get', null, true,
        (response) => {
          this.shipments = response.data
        }
      )
    }
  },
  created () {
    this.loadData()
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    AddShipment,
    ProcessProducts
  }
}
</script>

<style lang="scss" scoped src="@/assets/css/styles/my-shipblu-view.scss"></style>