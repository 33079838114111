<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="shipmentModal"
    :title="$t('Create Outgoing Shipment')"
    :buttons-hidden="true" autocomplete="nofill">
    <div class="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-1 sm:mt-2 mb-4">
      <div class="col-span-1 mt-5">
        <v-select :class="shipment.boxNumber ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"
          autocomplete="nofill" label="box_number" name="customer box number" v-model="shipment.boxNumber"
          :placeholder="$t('Customer Box Number') + '*'" :options="customers" v-validate="'required'"/>
        <span class="text-danger text-xs" v-show="errors.has('customer box number')">{{ errors.first('customer box number') }}</span>
      </div>
      <div class="col-span-1 mt-5">
        <v-select :class="shipment.slot ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"
          autocomplete="nofill" label="name" name="storage slot" v-model="shipment.slot" v-validate="'required'"
          :placeholder="$t('Storage Slot') + '*'" :options="slots"/>
        <span class="text-danger text-xs" v-show="errors.has('storage slot')">{{ errors.first('storage slot') }}</span>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-10">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button @click="addShipment" class="active-btn btn">{{ $t('Add') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../layouts/shipblu-components/ShipBluPrompt.vue'
import vSelect from 'vue-select'
import { sendRequest } from '../../../axios/requestHelper'

export default {
  props: ['shipmentModal', 'type', 'shipment'],
  data () {
    return {
      slots: [],
      customers: []
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  },
  watch: {
    'product' () {
      if (this.type === 'Edit Product') {
        this.sku = this.shipment.sku
      }
    },
    'shipmentModal' (val) {
      if (val === true) {
        this.loadCustomeBoxNumbers()
      }
    },
    'shipment.boxNumber' (val) {
      if (val && this.shipmentModal) {
        this.listSlots()
      }
    }
  },
  methods: {
    listSlots () {
      sendRequest(this, `api/storage/slots/list_available_slots/?box_number=${this.shipment.boxNumber.box_number}`, 'get', null, true,
        (response) => {
          this.slots = response.data
        }
      )
    },
    loadCustomeBoxNumbers () {
      sendRequest(this, 'api/storage/po-boxes/', 'get', null, true,
        (response) => {
          this.customers = response.data
        }
      )
    },
    closeModal () {
      this.$emit('shipmentModal', false)
      this.$emit('loadData')
    },
    addShipment () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const shipmentObj = {
            box_number: this.shipment.boxNumber.box_number,
            slot_id: this.shipment.slot.id
          }
          sendRequest(this, 'api/shipments/', 'post', shipmentObj, true,
            () => {
              this.closeModal()
            }
          )
        }
      })
    }
  }
}
</script>
